import axios from "axios";

const CRM_UI_BASE =
  "https://crm.luminartechnolab.com/payment/admission-form?website=y";

const CRM_API_BASE = "https://api.crm.luminartechnolab.com/api";
// const CRM_API_BASE = "https://ci2.deepnetsoft.com:3011/api";

export const openAdmissionFormCRM = () => {
  let newWindow = window.open(CRM_UI_BASE, "_self");
  const hasFocus = window.focus;
  if (hasFocus) {
    newWindow.focus();
  }
};

export const luminarCrmAxiosInstance = axios.create({
  baseURL: CRM_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
});

export const createEnquiryLuminarCRM = (data) => {
  const payload = data;
  if (!payload.email) delete payload.email;
  return luminarCrmAxiosInstance
    .post("/leads/create-website-enquiry-lead/public", payload)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getDropDownListLuminarCRM = (route) => {
  return luminarCrmAxiosInstance
    .get(`/${route}/list/public?_end=100`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};
